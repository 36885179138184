@import colors
@import typography

$radius-s: 4px    
$radius-m: 8px    
$radius-l: 16px

$mobile: 1023px
$desktop: 1024px

//max-width

.content-section, .header, footer
    max-width: 100%
 
.content-section > *, .header > *, footer > *
    width: 100%
    max-width: 1320px
    margin: 0 auto

//blocks
body
  margin: 0
  color: $text
*
    box-sizing: border-box

.row
    display: flex
    flex-wrap: wrap
    flex-direction: row
    @media screen and (max-width: 1000px)
        flex-direction: column

.stack
    display: flex
    flex-direction: column

.question-answer *
    color: $text-on-dark

.content-section
    @extend .stack
    padding: 64px 60px
    gap: 64px

    @media screen and (max-width: 1000px)
        padding: 32px 20px
        gap: 32px

.section-header
    @extend .stack
    gap: 32px
    @media screen and (max-width: 1000px)
        gap: 16px

footer
    @extend .stack
    padding: 64px 60px
    gap: 64px
    @media screen and (max-width: 1000px)
        gap: 32px
        padding: 32px 20px


.add-top 
    padding-top: 128px

.add-bottom
    padding-bottom: 128px


.cols
    display: flex
    flex: 1
    flex-wrap: nowrap
    gap: 32px
    @media screen and (max-width: 1000px)
        flex-direction: column

.cols > *
    flex: 1

.cols-2-1 > .col:first-child
    flex-grow: 2

.cols-2-1 > .col:last-child
    flex-grow: 1

.cols-1-2 > .col:first-child
    flex-grow: 1

.cols-1-2 > .col:last-child
    flex-grow: 2

img + .caption
    margin-top: 32px


.process-section
    background-color: $tertiary-light

//link
@media screen and (min-width: $desktop)
    a:hover
        border-bottom: none

@media screen and (max-width: $mobile)
    a:active
        border-bottom: none


//Button
button, .button
    padding: 16px 32px
    border-radius: $radius-m
    background-color: $primary
    border: none
    color: $text
    width: auto
    cursor: pointer
    width: fit-content
    text-decoration: none
    @media screen and (max-width: mobile)
        padding: 12px 24px

@media screen and (max-width: $mobile)
    .button-small
            height: 32px
            display: flex
            padding: 0 16px
            align-items: center

@media screen and (min-width: $desktop)
    button:hover, .button:hover
        background-color: $primary-hover

@media screen and (max-width: $mobile)
    button:active, .button:active
        background-color: $primary-hover



.title-section h3
    @extend h4


.title-section
    padding: 0 60px
    display: flex
    flex-direction: column
    position: relative

.title-section-card
    padding: 96px 0
    height: 100%
    left: 0
    right: 0
    display: flex
    align-items: center

.title-section-card-form
    box-sizing: border-box
    gap: 48px
    

.title-section-card-form-text
    flex: 2

.button-bar
    flex: 1


.video-bg
    height: 576px
    display: flex
    flex-direction: column
    overflow-y: hidden
    align-items: center
    align-content: center
    justify-content: center
    background: #FFF9CC
    border-radius: $radius-m
    transform: translateZ(0)


//header
.header
    padding: 22px 60px 26px
    border-bottom: 1px solid $border

.header .row
    justify-content: space-between

.logo-container
    font-size: 0
    line-height: 0

nav
    gap: 16px

//button-bar
.button-bar
    gap: 32px
    align-items: center


//Tag
.tag
    @extend .row
    align-items: center

.tag-rows-stack
    @extend .stack
    gap: 16px

.tag-row
    @extend .row
    gap: 8px

.tag-outlined:first-child:before
    display: none

.tag-outlined:before
    content:''
    display: block
    width: 10px
    height: 10px
    background-color: $secondary
    margin-right: 8px
    border-radius: $radius-m
    transform: translateZ(0)

.tag-filled
    padding: 0 16px
    height: 48px
    gap: 12px
    background-color: $secondary-light
    border-radius: $radius-m
    transform: translateZ(0)
    font-weight: 500








//WHY US
.why-us
    background: $secondary-light

.why-us__grid
    display: flex
    flex-direction: column
    gap: 56px
    .why-us-row
        gap: 24px
        display: flex
        @media screen and (max-width: 1023px)
            flex-direction: column
    .row
        gap: 24px
        @media screen and (max-width: 1023px)
            flex-direction: column
            gap: 24px
    .why-us__block.code, .why-us__block.masterclasses
        flex: 1
    .projects
        width: 100%
        display: flex
        gap: 24px
        @media screen and (max-width: 1023px)
            flex-direction: column
            gap: 8px
    .why-us__image-projects
        background-image: url('../assets/images/why-us-image-projects.png')
        aspect-ratio: 1096 / 744
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-projects@2x.png')
        @media screen and (max-width: 1023px)
            width: calc( 100% + 40px)
            margin-left: -20px
    .why-us__description-projects
        flex-basis: 198px
        @media screen and (max-width: 1023px)
            flex-basis: auto
    .why-us__block
        display: flex
        flex-direction: column
        gap: 8px
    .why-us__block.practice, .why-us__block.guidelines
        flex: 0 0 calc((100% - 12px) / 3 * 2)
    .why-us__block.why-us-certificate, .why-us__block.resume
        flex: 1
    .why-us__image-practice
        background-image: url('../assets/images/why-us-image-practice.png')
        aspect-ratio: 872 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-practice@2x.png')
        @media screen and (max-width: 1023px)
            width: calc( 100% + 40px)
            margin-left: -20px
    .why-us__image-certificate
        background-image: url('../assets/images/why-us-image-certificate.png')
        aspect-ratio: 424 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-certificate@2x.png')
        @media screen and (max-width: 1023px)
            width: calc(100% + 40px)
            margin-left: -20px
            aspect-ratio: 872/576
            background-size: cover
            background-position: 50%
    .why-us__image-resume
        background-image: url('../assets/images/why-us-image-resume.png')
        aspect-ratio: 424 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-resume@2x.png')
        @media screen and (max-width: 1023px)
            width: calc(100% + 40px)
            margin-left: -20px
            aspect-ratio: 872/576
            background-position: 50%
            background-color: $background-light
    .why-us__image-guidelines
        background-image: url('../assets/images/why-us-image-guidelines.png')
        aspect-ratio: 872 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-guidelines@2x.png')
        @media screen and (max-width: 1023px)
            width: calc( 100% + 40px)
            margin-left: -20px
    .why-us__image-masterclasses
        background-image: url('../assets/images/why-us-image-masterclass.png')
        aspect-ratio: 648 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-masterclass@2x.png')
        @media screen and (max-width: 1023px)
            width: calc( 100% + 40px)
            margin-left: -20px
    .why-us__image-code
        background-image: url('../assets/images/why-us-image-code.png')
        aspect-ratio: 648 / 576
        width: 100%
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/why-us-image-code@2x.png')
        @media screen and (max-width: 1023px)
            width: calc( 100% + 40px)
            margin-left: -20px


//CARDS

.cards-grid
    display: flex
    flex-direction: column
    gap: 32px

.cards-row
    display: flex
    flex-direction: row
    gap: 32px

.cards-row > *
    flex: 1

.card
    background-color: $background-light
    padding: 32px
    height: 576px
    border-radius: $radius-m
    transform: translateZ(0)
    @extend h4


.card-2-1
    flex-basis: 860px

.card-1-2 
    flex-basis: 428px


.features
    .card
        background-size: contain
        background-position: 50% 100%
        background-repeat: no-repeat

    .feature-1
        background-image: url('../assets/images/features1.png')

    .feature-2
        background-image: url('../assets/images/features2.png')

    .feature-3
        background-image: url('../assets/images/features3.png')

    .feature-4
        background-image: url('../assets/images/features4.png')

    .feature-5
        background-image: url('../assets/images/features5.png')

    .feature-6
        background-image: url('../assets/images/features6.png')

    .feature-7
        background-color: $background-dark
        color: $text-on-dark
        background-image: url('../assets/images/features7.png')

    .feature-8
        background-image: url('../assets/images/features8.png')

@media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
    .features
        .feature-1
            background-image: url('../assets/images/features1@2x.png')
        .feature-2
            background-image: url('../assets/images/features2@2x.png')
        .feature-3
            background-image: url('../assets/images/features3@2x.png')
        .feature-4
            background-image: url('../assets/images/features4@2x.png')
        .feature-5
            background-image: url('../assets/images/features5@2x.png')
        .feature-6
            background-image: url('../assets/images/features6@2x.png')
        .feature-7
            background-image: url('../assets/images/features7@2x.png')
        .feature-8
            background-image: url('../assets/images/features8@2x.png')










//process

.process-section:first-child
    padding-top: 128px

.process-1
    background-position: 50% 100%
    background-repeat: no-repeat
    background-size: contain
    width: 100%
    background-image: url('../assets/images/group-practice.png')
    aspect-ratio: 1320 / 860

.process-2
    background-position: 50% 100%
    background-repeat: no-repeat
    background-size: contain
    width: 100%
    background-image: url('../assets/images/process2.png')
    aspect-ratio: 1320 / 696

.process-3
    background-position: 50% 100%
    background-repeat: no-repeat
    background-size: contain
    width: 100%
    background-image: url('../assets/images/homework.png')
    aspect-ratio: 1320 / 795

.process-4
    background-position: 50% 100%
    background-repeat: no-repeat
    background-size: contain
    width: 100%
    background-image: url('../assets/images/process4.png')
    aspect-ratio: 1320 / 1469

//books

.process-books > .row
    gap: 32px
    border-radius: $radius-m
    transform: translateZ(0)
    padding-left: 48px
    background-color: $background

.process-books .books-text
    padding: 64px 0
    flex: 1

.process-books .books-img
    margin-top: auto
    margin-left: auto
    flex: 1
    flex-basis: 440px

.books-img
    background-image: url('../assets/images/process5.png')
    @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
        background-image: url('../assets/images/process5@2x.png')
    background-repeat: no-repeat
    background-position: 50% 100%
    width: 100%
    background-size: contain
    aspect-ratio: 440 / 384
    max-width: 440px

.process-books h2
    color: $text

.schedule
    background: $primary-light
.result
    background: $tertiary-light
.certificate-section
    background: $secondary-light

@media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
    .process-1
        background-image: url('../assets/images/group-practice@2x.png')
    .process-2
        background-image: url('../assets/images/process2@2x.png')
    .process-3
        background-image: url('../assets/images/homework@2x.png')
    .process-4
        background-image: url('../assets/images/process4@2x.png')

.schedule-img
    background-image: url('../assets/images/schedule.png')
    @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
        background-image: url('../assets/images/schedule@2x.png')
    background-repeat: no-repeat
    background-position: 50% 100%
    width: 100%
    background-size: contain
    aspect-ratio: 1320 / 576


.curruculum-rows
    display: flex
    flex-direction: column
    gap: 64px

.curriculum-row
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    gap: 64px

.curriculum-week
    padding-top: 16px
    border-top: 1px solid $border
    flex: 1
    display: flex
    flex-direction: column
    gap: 16px

.week-launch *
    color: $status-warning
.week-rest *
    color: $text-gray



//certificates

.certificate
    background-repeat: no-repeat
    background-position: 50% 100%
    width: 100%
    background-size: contain
    aspect-ratio: 300 / 376
    height: auto
    flex-basis: 300px

    &-shkolakadola
        background-image: url('../assets/images/certificate-shkolakadola.png')
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/certificate-shkolakadola@2x.png')
    &-ibm
        background-image: url('../assets/images/certificate-ibm.png')
        @media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
            background-image: url('../assets/images/certificate-ibm@2x.png')

.certificate-row
    display: flex
    gap: 32px
    flex-wrap: nowrap



//tutors
.tutor-block 
    gap: 32px

.card-tutor
    padding: 0
    background-color: $background
    gap: 8px
    display: flex
    flex-direction: column
    p, a
        font-size: 18px
        line-height: 28px
        font-weight: normal
    a
        margin-right: 12px
    .row
        gap: 4px

.avatar
    border-radius: $radius-m
    transform: translateZ(0)
    background-repeat: no-repeat
    background-position: 50% 100%
    width: 100%
    background-size: contain
    aspect-ratio: 1
    height: auto
    margin-bottom: 8px
    &-tutor
        width: 120px
        height: 120px
    &-kadol
        background-image: url('../assets/images/tutor-yeliseikadol.jpeg')
    &-vorobey
        background-image: url('../assets/images/tutor-oksanavorobey.jpg')


//enroll

.section-enroll
    background-color: $background-dark
    .section-header h3, .section-header p
        color: $text-on-dark
    .section-header h2
        color: $text-on-dark

.form
    background-color: $background
    padding: 32px
    border-radius: $radius-m
    transform: translateZ(0)
    gap: 32px
    display: flex
    flex-direction: column
.prices
    display: flex
    flex-direction: column
    gap: 0px

.price-old
    text-decoration: line-through

.price-actual
    color: $status-warning

.price-calculation
    background-color: $secondary
    padding: 16px
    gap: 16px
    display: flex
    flex-direction: column
    border-radius: $radius-m
    transform: translateZ(0)

.price-calculation-row
    display: flex
    justify-content: space-between
    gap: 32px


.phone-input
    @extend .typography-body
    gap: 16px
    align-items: baseline
    input
        @extend .typography-body
        background-color: $background-light
        border: none
        padding: 12px 16px
        flex: 1
        border-radius: $radius-s
        transform: translateZ(0)

.form form
    gap: 32px

.enroll-features
    gap: 32px

.enroll-feature
    gap: 16px

.enroll-feature-text
    flex: 1
    & > *
        color: $text-on-dark


.enroll-row
    gap: 64px
    align-items: center
    & > *
        flex: 1

ul
    padding: 0
    margin: 0

.section-enroll
    gap: 64px
    display: flex
    flex-direction: column


.section-groups
    background-color: $background-dark
    .section-header h3, .section-header p
        color: $text-on-dark
    .section-header h2
        color: $text-on-dark

.group-cards-row
    display: flex
    gap: 32px

.group-card
    flex: 1
    display: flex
    flex-direction: column
    padding: 0 32px
    background-color: $secondary
    border-radius: $radius-m
    transform: translateZ(0)

.group-card-dates-section
    display: flex
    flex-direction: column
    gap: 8px
    padding: 32px 0
    border-bottom: 1px solid $border

.group-card-dates
    color: $text

.group-card-time
    font-weight: bold

.group-card-tutor-section
    display: flex
    gap: 16px
    padding: 24px 0
    align-items: center

.group-card-tutor-avatar
    background-size: contain
    background-position: 50% 100%
    width: 64px
    height: 64px
    background-repeat: no-repeat
    border-radius: $radius-m
    transform: translateZ(0)
    flex-basis: 64px
    flex-shrink: 0

.group-card-tutor-avatar-yeliseikadol
    background-image: url('../assets/images/tutor-yeliseikadol.jpeg')

.group-card-tutor-avatar-oksanavorobey
    background-image: url('../assets/images/tutor-oksanavorobey.jpg')

.group-card-tutor-text-section
    display: flex
    flex-direction: column

.group-card-tutor-name
    font-weight: bold

.group-card-tutor-description
    color: $text-gray




.footer
    background-color: $background-dark
    & div
        color: $text-on-dark
    & a
        color: $text-on-dark
    & .cols
        align-items: flex-end

.footer-logo-container
    margin-bottom: -8px





.card-tutor-image
    color: transparent
    text-align: right

.student, .card-tutor-image
    background-position: 50% 100%
    background-size: contain
    background-repeat: no-repeat
    border-radius: $radius-m
    transform: translateZ(0)
    background-color: transparent
.student-1
    background-image: url('../assets/images/student1.jpg')
.student-2
    background-image: url('../assets/images/student2.jpg')
.student-3
    background-image: url('../assets/images/student3.jpg')
.student-4
    background-image: url('../assets/images/student4.jpg')
.student-5
    background-image: url('../assets/images/student5.jpg')
.card-tutor-yk1
    background-image: url('../assets/images/yk1.jpg')
.card-tutor-yk2
    background-image: url('../assets/images/yk2.jpg')
.card-tutor-yk3
    background-image: url('../assets/images/yk3.jpg')
.card-tutor-ov1
    background-image: url('../assets/images/ov1.jpg')
.card-tutor-ov2
    background-image: url('../assets/images/ov2.jpg')
.card-tutor-ov3
    background-image: url('../assets/images/ov3.jpg')

@media screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)
    .student-1
        background-image: url('../assets/images/student1@2x.jpg')
    .student-2
        background-image: url('../assets/images/student2@2x.jpg')
    .student-3
        background-image: url('../assets/images/student3@2x.jpg')
    .student-4
        background-image: url('../assets/images/student4@2x.jpg')
    .student-5
        background-image: url('../assets/images/student5@2x.jpg')
    .card-tutor-yk1
        background-image: url('../assets/images/yk1@2x.jpg')
    .card-tutor-yk2
        background-image: url('../assets/images/yk2@2x.jpg')
    .card-tutor-yk3
        background-image: url('../assets/images/yk3@2x.jpg')
    .card-tutor-ov1
        background-image: url('../assets/images/ov1@2x.jpg')
    .card-tutor-ov2
        background-image: url('../assets/images/ov2@2x.jpg')
    .card-tutor-ov3
        background-image: url('../assets/images/ov3@2x.jpg')


.title-section
    gap: 0
.hero-header
    gap: 16px

.color-purple
    color: $primary

.top-line
    background: $secondary-light
    padding: 16px 60px
    & > *
        max-width: 1320px
        margin: 0 auto
        display: flex
        gap: 8px
        align-items: center

.flex-column 
    display: flex
    flex-direction: column

.input-container
    gap: 8px

input, select
    @extend .typography-body
    padding: 12px
    background: $field
    border: 1px solid $transparent
    border-radius: $radius-m
    transform: translateZ(0)
    &:focus
        outline: 0
        border: 1px solid $border-focus
        background: $background

//media query

@media screen and (max-width: 1000px)
    .top-line
        padding: 16px 16px
    .cards-row
        gap: 16px
    .card
        height: auto
        width: 100%
        aspect-ratio: 7 / 9
    .curruculum-rows
        gap: 64px
    .curriculum-row
        flex-direction: column
        gap: 64px
    body
        overflow-x: hidden

    .header
        padding: 16px 20px
        left: 0
        width: 100%
        position: -webkit-sticky
        position: sticky
        top: 0
        z-index: 10
        background: $background
        border: 0

    .header .row
        flex-direction: row
        align-items: center
    .header svg
        height: 40px
        width: auto
    .title-section
        padding: 0
    .video-bg
        height: 60vw
        border-radius: $radius-m
        transform: translateZ(0)
    .video-bg video
        object-fit: cover
        height: 100%
    .title-section-card
        background-color: $background
        padding: 64px 20px

    .tag-row, .tag
        flex-direction: row
    .tag-rows-stack
        gap: 0
    .tag-outlined:before
        width: 5px
        height: 5px
    .tag-outlined:last-child
        margin-right: 0
    .title-section-card-form
        gap: 32px

    .button-bar
        gap: 16px

    .add-top
        padding-top: 64px
    .add-bottom
        padding-bottom: 64px
    .card
        border-radius: $radius-m
        transform: translateZ(0)

    .process-books > .row, .process-books > .tag, .process-books > .tag-row
        gap: 16px
        border-radius: $radius-m
        transform: translateZ(0)
        padding-left: 24px
        padding-right: 24px
    .process-books .books-text
        padding: 24px 0
    .process-books .books-img
        flex-basis: auto
        margin-right: -24px
        width: calc(100% + 24px)
    .cards-grid
        gap: 16px
    .certificate-row
        gap: 16px
    
    .card-2-1, .card-1-2
        flex-basis: auto
    .group-cards-row
        flex-direction: column
        gap: 16px
    .enroll-row .form
        width: 100%
        flex: auto
    .phone-input
        flex-direction: row
        flex-wrap: nowrap
    .phone-input input
        width: 100%
    .footer .cols
        align-items: flex-start
    .section-enroll
        gap: 32px
    .card-tutor
        flex-basis: 0
        .row
            flex-direction: row
    .card-tutor-image
        aspect-ratio: 1
        background-size: cover
    .cards-row-tutor .card-2-1
        aspect-ratio: 1
        background-size: cover
    .student
        aspect-ratio: 1
        background-size: cover
    .cards-row
        display: flex
        flex-direction: column
    .cards-row-tutor
        display: flex
        flex-direction: column-reverse
        gap: 16px
    .button-bar
        flex-direction: row
    .group-card-tutor-avatar
        width: 40px
        height: 40px
        flex-basis: 40px
    .group-card-tutor-text-section
        gap: 4px
    .form
        padding: 24px
        border-radius: $radius-m
        transform: translateZ(0)
    .student, .card-tutor-image
        border-radius: $radius-m
        transform: translateZ(0)
        font-size: 0
        line-height: 0
    .cards-row-tutor .card-tutor-yk1
        background-position-x: 0
    .header nav.row
        margin-top: 0
        flex-direction: column
        gap: 0



.logo h2
    font-size: 0
.logo
    background-image: url('../assets/logo-black@2x.svg')
    width: 188px
    height: 56px
    @media screen and (max-width: $mobile)
        background-image: url('../assets/logo-black.svg')
        width: 94px
        height: 28px
    &-inverted
        background-image: url('../assets/logo-white@2x.svg')
        @media screen and (max-width: $mobile)
            background-image: url('../assets/logo-white.svg')

.top-line-link 
    color: $text

.title-eyebrow
    margin-bottom: 16px






// enroll-form
.input-label
    @extend .caption

.enroll-form form
    gap: 32px




.hidden
    display: none



@media screen and ( max-width: $mobile )
    .button-big
        font-size: 19px
        line-height: 24px
        letter-spacing: 0
        font-weight: 500
        margin: 0
        width: 100%
        text-align: center

.checkbox-label
    display: flex
    gap: 12px
.checkbox-group
    display: flex
    flex-direction: column
    gap: 16px

.checkbox
    display: flex
    width: 25px
    height: 24px
    background-position: 50%
    background-size: cover
    background-image: url(../assets/notChecked.svg)
    &-checked
        background-image: url(../assets/checked.svg)

.enroll-sum
    gap: 16px
    padding: 24px
    background: $secondary
    border-radius: 8px

.enroll-form
    padding-bottom: 64px