@import colors

$mobile: 1023px
    
@font-face 
    font-family: 'tt-wellingtons'
    src: url(../assets/fonts/TTWellingtonsMedium.woff2)
    font-weight: normal

@font-face 
    font-family: 'tt-wellingtons'
    src: url(../assets/fonts/TTWellingtonsDemiBold.woff2)
    font-weight: 600

@font-face 
    font-family: 'tt-wellingtons'
    src: url(../assets/fonts/TTWellingtonsExtraBold.woff2)
    font-weight: bold

*
    font-family: 'tt-wellingtons', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-feature-settings: 'lnum', 'ss01'
    color: $label-primary






h1, %h1, .typography-h1
    font-size: 56px
    line-height: 62px
    letter-spacing: -1px
    font-weight: 500
    margin: 0
    @media (max-width: $mobile)
        font-size: 32px
        line-height: 40px
        letter-spacing: 0

h2, %h2, .typography-h2
    font-size: 40px
    line-height: 48px
    letter-spacing: -1px
    font-weight: 500
    margin: 0
    @media (max-width: $mobile)
        font-size: 27px
        line-height: 32px
        letter-spacing: 0

h3, %h3, .typography-h3
    font-size: 28px
    line-height: 40px
    font-weight: 500
    margin: 0
    letter-spacing: 0
    @media (max-width: $mobile)
        font-size: 22px
        line-height: 28px

h4, %h4, .typography-h4
    font-size: 19px
    line-height: 24px
    letter-spacing: 0
    font-weight: 500
    margin: 0

h5, %h5, .typography-h5, .typography-eyebrow
    font-size: 15px
    line-height: 20px
    letter-spacing: 0
    font-weight: bold
    margin: 0
    font-feature-settings: 'pnum' on, 'lnum' on, 'cpsp' on
    text-transform: uppercase

body, %body, .typography-body
    font-size: 19px
    line-height: 24px
    letter-spacing: 0
    font-weight: normal
    margin: 0

.typography-body2, %body2, caption, .caption, %caption
    font-size: 15px
    line-height: 20px





p
    margin: 0

button, .typography-button, .button
    font-size: 19px
    line-height: 24px
    letter-spacing: 0
    font-weight: 500
    margin: 0
    @media (max-width: $mobile)
        font-size: 15px
        line-height: 20px

caption, .caption, %caption
    margin: 0
    text-align: left
    display: flex

code, .code, %code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

a
    color: $link
    text-decoration: none