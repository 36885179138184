$white: #ffffff
$black: #000000
$gray-10: #f5f5f5
$gray-20: #ededed
$gray-60: #717171
$gray-90: #1a1a1a
$blue-5: #D9E5FF
$blue-60: #1A47B8
$blue-80: #042165
$orange-50: #E0592A
$yellow-5: #FFFCE6
$yellow-10: #FEF6B1
$purple-5: #F6D9FF
$purple-20: #DAB0EE
$purple-40: #D28AF5
$purple-80: #821fb4
$transparent: transparent

$text: $gray-90
$text-on-dark: $white
$background: $white
$background-light: $gray-10
$background-dark: $black
$primary: $purple-20
$primary-hover: $purple-40
$primary-light: $purple-5
$secondary: $yellow-10
$secondary-light: $yellow-5
$tertiary-light: $blue-5
$link: $blue-60
$link-hover: $blue-80
$status-warning: $orange-50
$field: $gray-20
$border-strong: $gray-60
$border-focus: $purple-80

$green: #2CA03C
$green-light: #DDFAE3
$greed-inverted: #A6ECB3
$gray-5: #F5F5F5
$gray-10: #E7E7E7

$gray-80: #111111

$red: #E23636

$background: $white
$background-darker: $gray-5
$accent: #2CA03C
$accent-light: #DDFAE3
$accent-hover: #116e1e
$accent-inverted: $greed-inverted
$text: #222222
$text-inverted: #FFFFFF
$text-red: $red
$text-gray: #777777
$border: rgba(0,0,0,0.12)

$accent-label: $green
$accent-label-inverted: $greed-inverted
$accent-bg: $green-light
$cta-bg: $gray-80
$cta--onBg: $white
$cta-link: $green
$label-primary: $gray-80
$label-secondary: #777777
$label-disabled: #BBBBBB
$label-inverted: $white
$bg-primary: $white
$bg-onPrimary: $gray-10
$bg-inverted: $gray-80
$bg-onInverted: #999999
$bg-accent: $green
$status-error-text: #E23636
$status-error-bg: #EECFCF